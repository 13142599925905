
import { Vue, Options } from 'vue-class-component';
import { LegalPolicy } from '@/lib/components';
import { useUiStore } from '@/stores/ui.store';

@Options({
  components: { LegalPolicy },
  props: {
    branding: {
      type: String,
      default: null
    }
  }
})
export default class PrivacyPolicyPage extends Vue {
  uiStore = useUiStore();

  get countryCode(): string {
    return this.$route.params.region;
  }
}
